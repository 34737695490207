import request from '@/utils/request';

// 登录
export function loginApi(data) {
    return request({
        url: `/demo/login`,
        method: 'post',
        data
    });
}

// 核销
export function snApi(data) {
    return request({
        url: `/demo/sn`,
        method: 'post',
        data
    });
}