<template>
  <div class="bg">
    <div class="login">
      <h2>回瓶核销系统</h2>
      <el-form ref="form" :model="form" label-width="20%">
        <el-form-item label="用户名:">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="密  码:">
          <el-input v-model="form.password" type="password"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" round @click="login" class="btn">登录</el-button>
      <!-- <span @click="register" class="reg">前往注册</span> -->
    </div>
  </div>
</template>

<script>
import { loginApi } from '@/api/api.js'
export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    };
  },

  methods: {
    login() {
      if (this.form.username == '') {
        this.$message.error('用户名不能为空');
      } else if (this.form.password == '') {
        this.$message.error('密码不能为空');
      } else {
        loginApi({
          name: this.form.username,
          password: this.form.password
        }).then(res => {
          console.log(res)
          if (res.code == 1) {
            this.$router.push({
              path: '/home',
              query: {
                name: this.form.username
              }
            })
          } else {
            this.$message.error(res.msg);
            this.form.username = ''
            this.form.password = ''
          }
        }).catch(err => {
          console.log("登录失败" + err);
        })
      }
    },
    register() {
      this.$router.push('/register')
    }
  }
}
</script>

<style scoped>
.bg {
  width: 100vw;
  height: 100vh;
  background: url("../assets/img/banner.jpg");
  background-position: center center;
  background-size: cover;
}

.login {
  height: 250px;
  width: 350px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
  background: #ffffff;
  opacity: 0.85;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 20px;

}

@media screen and (min-width: 320px) and (max-width: 1080px) {
  .login {
    margin: 0 auto;
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1536px) {
  .login {
    position: fixed;
    top: calc(10%);
    left: calc(50%);
  }
}

@media screen and (min-width: 1366px) {

  .login {
    position: fixed;
    top: calc(50% - 400px);
    right: calc(50% - 870px);

  }
}


.login h2 {
  padding-bottom: 30px;
}

.btn {
  width: 60%;
  margin: auto;
}

.reg {
  color: red;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}
</style>