import axios from 'axios'
import { Notification, Message } from 'element-ui' //引入element-ui库
import { getToken } from '@/utils/auth'; // 引入Cookies
// 创建axios实例
const service = axios.create({
	baseURL: process.env.API_ROOT, // 设置请求的基本URL
	// baseURL: 'https://dc.sdmjkj.com/index.php/api',
	timeout: 5000, // 请求超时时间
	withCredentials: true, //表示跨域请求时是否需要使用凭证
})
// request拦截器
service.interceptors.request.use(
	config => {
		if (getToken()) {
			config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
		} else {
			config.headers['Authorization'] = `Bearer ${''}`;
		}
		return config
	},
	error => {
		Promise.reject(error)
	}
)
// 拦截器方式 一
// response 
service.interceptors.response.use(
	response => {
		return response.data
	},
	error => {
		// 兼容blob下载出错json提示
		if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
			const reader = new FileReader()
			reader.readAsText(error.response.data, 'utf-8')
			reader.onload = function () {
				const errorMsg = JSON.parse(reader.result).message
				Notification.error({
					title: errorMsg,
					duration: 5000
				})
			}
		} else {
			let code = 0
			try {
				code = error.response.data.status
			} catch (e) {
				if (error.toString().indexOf('Error: timeout') !== -1) {
					Notification.error({
						title: '网络请求超时',
						duration: 5000
					})
					return Promise.reject(error)
				}
			}

			if (code) {
				if (code === 401) {
					//  store.dispatch('LogOut').then(() => {
					//      // 用户登录界面提示
					//      Cookies.set('point', 401)
					//      location.reload()
					// })
				} else if (code === 403) {
					//  router.push({ path: '/401' })
				} else {
					const errorMsg = error.response.data.message
					if (errorMsg !== undefined) {
						Notification.error({
							title: errorMsg,
							duration: 0
						})
					}
				}
			} else {
				Notification.error({
					title: '接口请求失败',
					duration: 5000
				})
			}
		}
		return Promise.reject(error)
	}
)
// 拦截器方式 二

// service.interceptors.response.use(
//   res => {
//     if (res.status === 200) {
//       return res.data;
//     } else {
//       return Promise.reject(new Error(res.data.Data.Message || 'Error'));
//     }
//   },
//   error => {
//     Message.error(showStatus(error.response.status)); // , error.response.data
//     return Promise.reject(error);
//   }
// );


// const showStatus = (code, errMessage) => {
//   let message = '';
//   switch (code) {
//     case 400: message = errMessage || '请求错误(400)'; break;
//     case 401: message = '未授权，请重新登录(401)'; break;
//     case 403: message = '拒绝访问(403)'; break;
//     case 404: message = '请求出错(404),未找到文件'; break;
//     case 408: message = '请求超时(408)'; break;
//     case 500: message = '服务器错误(500)'; break;
//     case 501: message = '服务未实现(501)'; break;
//     case 502: message = '网络错误(502)'; break;
//     case 503: message = '服务不可用(503)'; break;
//     case 504: message = '网络超时(504)'; break;
//     case 505: message = 'HTTP版本不受支持(505)'; break;
//     default: message = `连接出错(${code})!`;
//   }
//   return `${message}`;
// };

//将service导出
export default service; 